import PropTypes from "prop-types"
import styled from "styled-components"
import React from "react"
import Box from "./Box"
import Flex from "./Flex"
import Heading2 from "./Headings/Heading2"
import Heading3 from "./Headings/Heading3"
import BaseList from "./List"
import { StyledLink } from "./Link"

const List = styled.ol`
  list-style-type: none;
  counter-reset: section;

  & > li {
    counter-increment: section;
    padding-left: 45px;

    & > *:first-child {
      position: relative;
      :before {
        content: counter(section, decimal) ".";
        left: -45px;
        position: absolute;
        width: 45px;
      }
    }
  }
`

const PolicyHeader = styled(Heading2)`
  font-size: 1.5rem;
`

const PolicyList = styled.ol`
  counter-reset: clause;
  list-style-type: none;

  & > li {
    counter-increment: clause;
    padding-left: 45px;
    line-height: 1.9;
    font-size: 1.1rem;

    & > *:first-child {
      position: relative;

      :before {
        content: counter(section) "." counter(clause, decimal);
        left: -45px;
        position: absolute;
        width: 45px;
      }
    }

    & ol {
      padding-left: 45px;
    }
  }
`

const LegalList = ({ sections, description }) => (
  <Flex flexWrap={"wrap"} mx={2}>
    <Box px={2} py={2} width={["100%", 1 / 3]} as={"nav"}>
      <Heading3>Table of Contents</Heading3>
      <BaseList as="ol">
        {sections.map((section, key) => (
          <li>
            <StyledLink href={`#legal-${section.title}`}>
              {section.title}
            </StyledLink>
          </li>
        ))}
      </BaseList>
    </Box>
    <Box px={2} py={2} width={["100%", 2 / 3]}>
      {description}
      <List>
        {sections.map(({ title, policies = [], children }) => {
          return (
            <li key={title}>
              <PolicyHeader id={`legal-${title}`}>{title}</PolicyHeader>
              {children}
              {policies.length > 0 && (
                <PolicyList>
                  {policies.map((policy) => (
                    <li key={policy}>
                      <p>{policy}</p>
                    </li>
                  ))}
                </PolicyList>
              )}
            </li>
          )
        })}
      </List>
    </Box>
  </Flex>
)

LegalList.propTypes = {
  sections: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.node.isRequired,
      policies: PropTypes.arrayOf(PropTypes.node),
    })
  ),
}

export default LegalList
