import styled from "styled-components"
import Box from "../Box"

const Heading2 = styled(Box).attrs({
  as: "h2",
})`
  font-size: 1.9rem;
  margin: 1rem 0;
`

Heading2.propTypes = {}

export default Heading2
