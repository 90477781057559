import React from "react"
import Page from "../../components/Page"
import Link from "../../components/Link"
import LegalList from "../../components/LegalList"

const TermsOfService = () => (
  <Page title={"Terms of service"}>
    <LegalList
      sections={[
        {
          title: "General Introduction",
          policies: [
            "The general terms and conditions (hereinafter the “Conditions”) automatically apply to all current and future offers, orders, agreements and deliveries with respect to the supply of services and/or products by ChangeHub to the Customer (the “Services”).",
            "By accessing or using the Services of ChangeHub or by signing up for an account, you (hereinafter the “Customer” or “you”) accept these Conditions and are a party to these Conditions.",
            "The present Conditions and the Privacy Policy are an integral part of the agreement between Application name and the Customer (the “Agreement”).",
          ],
        },
        {
          title: "Changes to or deviations from the Conditions",
          policies: [
            "From time to time, ChangeHub may change or adapt these Conditions by publishing the revised Conditions on its website. ChangeHub will notify you by sending a notice via email to the email address associated with your account, or by providing a notice through its Services. Unless stated otherwise in this notice, the revised Conditions will be effective immediately and your continued access to and use of the Services after being provided with the notice will serve as your acceptance of the changed Conditions. If you do not agree with the amended Conditions, you are required to stop accessing and using the Services.",
            "Deviations from the Conditions are only enforceable vis-à-vis ChangeHub if ChangeHub has expressly accepted these deviations in writing. In such cases, the remainder of the Conditions continues to apply. The Customer cannot derive any rights from previously agreed deviations with respect to future services.",
          ],
        },
        {
          title: "Accounts and Security",
          policies: [
            "You are required to complete the registration form on the sign-up page in order to use the Services. You will provide true, accurate, current, and complete information as requested in the registration form. As part of the registration process, you will provide an email address, username and password for your ChangeHub account.",
            "You are responsible for ensuring and maintaining the security of your account, username, passwords, and files and are responsible for all uses of your account and of the Services in your name or on your behalf. ChangeHub reserves the right to refuse the registration of or to cancel the accounts that it deems inappropriate. You must promptly notify ChangeHub if you discover or suspect that someone has accessed your account without your permission.",
            "By creating a ChangeHub account, you consent to receive electronic communications from ChangeHub (e.g. via email or by posting notices on our Services). These communications may include notices about your account (e.g. payment authorizations, password changes and other transactional information) or other communications, and are part of your relationship with Application name. You agree that any notices, agreements, disclosures or other communications that we send to you electronically will satisfy any legal communication requirements, including, but not limited to, that such communications be in writing.",
          ],
        },
        {
          title: "Obligations of the Customer",
          policies: [
            "The Customer shall at all times cooperate with ChangeHub in good faith in order to facilitate the delivery of the Services.",
            "The Customer shall at all times use the Services in accordance with and subject to all provisions of applicable law.",
            "If the Customer fails to comply with its obligations under this section 4, he or she agrees to bear all costs and charges related to the extra services or other actions performed by ChangeHub and needed to remedy any issues resulting from Customer’s shortcomings to comply with its obligations under this section.",
          ],
        },
        {
          title: "Plans, Pricing and Invoicing",
          policies: [
            "When signing up for the Services, you agree to a monthly or yearly subscription with ChangeHub. When you register for a subscription, you expressly acknowledge and agree that a) ChangeHub is authorized to charge you a monthly or yearly fee for your subscription (in addition to any applicable taxes and other charges) for as long as your subscription continues, and b) your subscription is continuous until you cancel it or ChangeHub suspends or stops providing access to the Services in accordance with these Conditions. For more information about pricing and subscription plans, please visit the pricing page on the ChangeHub website (https://changehub.io).",
            "Prices are indicated on the pricing page on the website (https://ChangeHub/home). Pricing in offers is valid for the period indicated in the offer, or, if no period is mentioned, for a period of fifteen (15) days. Unless stated otherwise, all prices are in Euros and excluding Belgian VAT (BTW). ChangeHub reserves the right to change the prices of the Services at any time during the period of the Agreement. The Customer will be notified of such change via an individual notification via e-mail or through the Services.",
            "Invoices are payable and due within thirty (30) calendar days of the invoice date.",
            "By providing a credit card or other payment method that ChangeHub accepts, you represent and warrant that you are authorized to use the designated payment method. In addition, you authorize ChangeHub (or a third party payment processor) to charge your payment method for the total amount of your subscription fees (and applicable taxes and other charges). If the payment method cannot be verified, is invalid or is otherwise not acceptable, your account may be suspended until your payment has been processed. You must resolve any problem ChangeHub encounters in order to proceed.",
            "Payment of an invoice, or a part thereof, implies the acceptance of the entire invoice and of the delivery of the invoiced Services.",
            <>
              <p>In case of non-payment of an invoice on the due date:</p>
              <ol>
                <li>
                  the Customer, as from the expiry date, automatically and
                  without prior formal notice (‘ingebrekestelling’; ‘mise en
                  demeure’), shall be liable to pay a delay interest of twelve
                  (12) percent per year on the amount still due. The above
                  interest is capitalized.
                </li>
                <li>
                  the Customer shall also be liable to pay an additional fixed
                  compensation for administrative costs amounting to ten (10)
                  percent of the invoiced amount, with a minimum of seventy-five
                  (75) Euros and a maximum of two hundred and fifty (250) Euros.
                </li>
                <li>
                  the Customer shall pay any legal and extra-legal costs
                  incurred for collecting the outstanding invoices.
                </li>
                <li>
                  ChangeHub is entitled to exercise its rights under section 6.
                </li>
              </ol>
            </>,
            "Partial payments are always accepted with all reservations and without any acknowledgment or prejudice and are set off against, firstly, any outstanding legal costs related to the collecting of outstanding amounts, if any, secondly, the expired interests, thirdly, the compensation for administrative costs and finally, against the principal amount.",
            "An invoice can only be protested in writing within a period of fourteen (14) calendar days after the date of invoice. After expiry of this period, the Customer is deemed to have accepted the invoice.",
            "The Customer can only suspend a payment, if he demonstrates that ChangeHub has failed to comply with a contractual obligation and that he has priory informed ChangeHub thereof in writing. In any case, the amount of the suspended payment can only refer to that part of the Services that corresponds with the (alleged) non-compliance.",
          ],
        },
        {
          title: "Termination, Cancellation and Suspension",
          policies: [
            "Either party may suspend the Services or terminate the Agreement immediately, without any prior recourse to the court and without any further notice, if the other party (i) materially fails to perform any of its obligations under this Agreement, and such failure is not substantially remedied within twenty (20) working days after written notice from the other party to the failing party, reasonably specifying the nature of the failure; or (ii) becomes subject of bankruptcy, insolvency or a similar proceeding, or otherwise liquidates or ceases to do business.",
            <>
              You may cancel your subscription at any time by contacting
              ChangeHub at{" "}
              <Link to={"mailto:changehub@madewithlove.com"} external>
                changehub@madewithlove.com
              </Link>
              . Such cancellation notice must be sent by the designated account
              owner or an authorized signatory. Inactivity does not constitute
              automatic cancellation. Therefore, unless having cancelled your
              account in accordance with this section, you will continue to be
              charged for the subscription to the Services. All cancellation
              requests will take effect only at the end of then-current
              subscription period in which the cancellation request has been
              made, and you will be responsible for all fees and any applicable
              taxes and other charges incurred through the cancellation date. In
              the event you cancel your subscription, please note that ChangeHub
              may still send you promotional communications about ChangeHub,
              unless you opt out of receiving those communications by following
              the unsubscribe instructions provided in those communications. The
              cancellation of your account does not entitle you to any refund or
              compensation in any way, also with regard to previous payments."
            </>,
            "ChangeHub may suspend the provision of any or all Services or may terminate this Agreement at its sole discretion, without any prior recourse to the court and without any further notice, if the Customer fails to timely pay any undisputed amount due to ChangeHub pursuant to the Agreement, and does not cure such default within ten (10) working days after being given written notice by ChangeHub.",
          ],
        },
        {
          title: "Intellectual Property Rights and Licenses",
          policies: [
            "The performance by ChangeHub of its obligations under this Agreement or any order pursuant thereto shall not entail a transfer of any intellectual, industrial or other proprietary rights to the Customer or to any of its affiliates, and the charges paid by the Customer to ChangeHub under this Agreement or any order pursuant thereto shall not include any compensation for a transfer of any intellectual, industrial or other proprietary rights.",
            "Any intellectual, industrial or other proprietary rights with respect to works created by Application name, its employees, its consultants or its subcontractors, whether or not created in the performance of the Agreement or any order pursuant thereto, such as software, documentation, materials, documents, drawings, technology, skills, know-how and information related to the software, whether or not preceding the beginning of the Agreement, shall be vested exclusively in or licensed to ChangeHub and/or its respective licensors, as the case may be.",
            "If the supply of the Services requires the use of software that is the property of ChangeHub or for which the latter has been granted a license, then ChangeHub will grant a non-exclusive, non-transferable license to the Customer to use the software during the period in which ChangeHub provides the Services to the Customer, and only to such an extent as required to allow the Customer to make use of the Services. Such license is included in the price as set forth in the Agreement or order.",
            "The license does not give the Customer the right to (a) copy, sell, lend, give, lease, grant, market, divulge the software or in any other way make it available to third parties; (b) reproduce, remanufacture, or in any other way duplicate the software; (c) (try to) reverse-assemble, reverse-compile or reverse-engineer the software; and (d) use the software with the intention to (help to) develop software or any other method or module, so as to execute (substantially) the same function as (part of) such software.",
            "Any intellectual, industrial or other proprietary rights with respect to any adjustments, modifications and updates to software or to any materials, documents, technology, drawings, skills, know-how and information related to the Services, or software, that have been made as a result of information, advice or any know-how originating from the Customer or any of its affiliates shall be vested exclusively in ChangeHub. The Customer and its affiliates shall refrain from claiming any rights related to the aforementioned intellectual, industrial or other proprietary rights or any similar protection, and from taking any steps to obtain a registration, filing or protection of any alleged rights related thereto.",
            "If the performance of the Services entails the supply of licenses for third-party software, the Customer undertakes to strictly comply with the license conditions as supplied by the relevant third-party software suppliers.",
            "Each party indemnifies the other party against any third-party claims arising out of breach of any of the provisions of this section 7 by the first-mentioned party.",
            "ChangeHub retains the sole rights and ownership of all code, text and graphics used in the Services (including goodwill). Should the Agreement be terminated by either party, the Customer will own full rights to his/her own list data held in the ChangeHub database which can be transferred to the Customer at no cost, provided that there are no outstanding sums owed to ChangeHub. You acknowledge and agree that the Services and the ChangeHub company names, logos, all related product and service names, design marks and slogans (collectively, the “Trademarks”) are the property of ChangeHub or its affiliates or suppliers. You are not authorized to use any of the Trademarks in any advertising, publicity or any other commercial manner without the prior written consent of ChangeHub. Your use of the Services confers no title or ownership in the Services, the software or the Trademarks and does not constitute a sale of any rights in the Services, the software or the Trademarks. All ownership rights remain with ChangeHub or its third party suppliers, as the case may be.\n",
          ],
        },
        {
          title: "Client Data",
          policies: [
            <>
              <p>
                When using the Services, you may import data regarding your
                clients (“Client Data”). ChangeHub uses Client Data only to
                provide the Services to you and at your direction, and does not
                disclose Client Data to third parties, except as follows:
              </p>
              <ol>
                <li>
                  ChangeHub may share Client Data with third party service
                  providers to provide the Services or to administer the
                  website.
                </li>
                <li>
                  ChangeHub may disclose Client Data as it reasonably believes
                  is necessary to comply with any judicial or governmental
                  subpoenas, warrants or orders.
                </li>
                <li>
                  ChangeHub reserves the right to use, disclose and share your
                  information and Client Data to investigate, prevent or take
                  action with respect to any potential or actual fraud, illegal
                  activities, circumstances which threaten the physical safety
                  of any person, violations of these Conditions or as otherwise
                  required by law.
                </li>
              </ol>
            </>,
            "The Customer is responsible for adequately and properly informing the data subjects and for obtaining all required consents regarding the above mentioned usages and transfers of Client Data.",
          ],
        },
        {
          title: "Confidential Information",
          policies: [
            "“Confidential Information” means any information in any format disclosed or made available by or on behalf of a party to the other party in respect of this Agreement or any order pursuant thereto, except for information that is: a) already in the public domain other than by a breach of this Agreement; b) rightfully received from a third party not in breach of any obligations of confidentiality; c) independently developed by any employees or agents of one party without access to the confidential information of the other party; d) proven to be already known to the receiving party at the time of disclosure; e) produced in compliance with applicable law or a court order, provided that the receiving party first gives the disclosing party reasonable notice of such law or order and an opportunity to object to and/or attempt to limit such disclosure.",
            "Each party shall treat Confidential Information as strictly confidential, shall not disclose or make it available in any way to third parties without the prior written consent of the other party and shall only use it in connection with the performance of this Agreement or any order pursuant thereto.",
            "Each party shall only disclose and make available Confidential Information to those employees, agents and subcontractors who are involved in the performance of this Agreement and provided that they are subject to the same obligation of confidentiality as that set forth in this section. Such disclosure shall always be on a strict “need-to-know” basis.",
            "This section 9 shall survive the termination of this Agreement for a period of twelve (12) months.",
          ],
        },
        {
          title: "Liability",
          policies: [
            "ChangeHub does not control, endorse or takes any responsibility for any content or third-party content available on or linked to its Services. Your use of the Services is at your sole risk. The Services are provided “as is” and “as available” without warranties of any kind, either express or implied, including, but not limited to, implied warranties of merchantability, fitness for a particular purpose, availability, accuracy, omissions, completeness, timeliness or delays with respect to the service, information or products.",
            "ChangeHub does not warrant that the functions contained in the Services will be uninterrupted or error-free. Whilst ChangeHub will endeavor to ensure that the Services have undergone full testing, in no event ChangeHub will be liable to the Customer or to any third party for any damages, including, but not limited to service interruptions caused by any circumstances beyondChangeHub’s control, any lost profits, lost savings or other incidental, consequential or special damages arising out of the operation of or inability to operate the Services, failure of any service provider, any telecommunications carrier, the internet backbone, any internet servers, your or the site visitor's computer or internet software, even if ChangeHub has been advised of the possibility of such damages.",
            "ChangeHub will not be held liable for any consequential or indirect loss or damage suffered by the Customer or its affiliates, such as, as the case may be, any loss of data, profits, revenue, turnover or any other financial or commercial losses, whether this loss or damage arises from a breach of contract or duty in tort.",
            "The aggregate liability of each party to the other for any damages, shall be limited to the total amount paid by the Customer for the Services during the three (3) months preceding the event giving rise to the damages.",
          ],
        },
        {
          title: "Indemnification",
          policies: [
            "At ChangeHub's request, you agree to defend, indemnify and to hold harmless ChangeHub, its officers, directors and employees from any claims arising from the Customers use of the Services or the use on the Customer’s behalf, from the Customer's breach of the Conditions, including, without limitation, claims of copyright infringement, patent infringement, misappropriation of trade secrets, libel, slander, trade libel, defamation, harassment, invasion of privacy or fraud.",
          ],
        },
        {
          title: "Force Majeure",
          policies: [
            "Events of Force Majeure shall relieve either party, for so long as such event continues, from those of its obligations under this Agreement the delivery of which has become impossible or impracticable, provided that such party promptly notifies the other party in writing describing the Force Majeure and immediately continues the performance of the obligations concerned when and to the extent that the Force Majeure event is removed. The parties may terminate this Agreement by notice in the event that the performance of the Agreement remains suspended by Force Majeure for a period exceeding thirty (30) working days.",
            "Neither party shall be entitled to claim damages for any non-performance by the other party of any of its contractual obligations resulting from an event of Force Majeure.",
          ],
        },
        {
          title: "Assignment",
          policies: [
            "The rights granted under this Agreement may not be transferred or assigned by you or your company.",
            "ChangeHub is entitled to transfer and assign the rights of this Agreement to any third party.",
          ],
        },
        {
          title: "Miscellaneous",
          policies: [
            "Should any term of this Agreement be finally determined by a court of a competent jurisdiction to be invalid, unenforceable or otherwise contrary to law and equity, the parties agree that such provision shall be construed, limited, modified or, if necessary, severed, to the extent necessary to eliminate its invalidity or unenforceability, and that the other provisions of this Agreement shall remain unaffected.",
            "Without prejudice to section 2.2, no waiver, amendment, or modification of any provision of this Agreement shall be effective unless agreed to by both parties in writing. No failure or delay by either party in exercising any rights, power, or remedy under this Agreement shall operate as a waiver of any such right, power, or remedy.",
          ],
        },
        {
          title: "Applicable law and Jurisdiction",
          policies: [
            "These Conditions and any disputed relating thereto or resulting therefrom, are solely governed by Belgian law.",
            "The Courts of Leuven shall have exclusive jurisdiction over any dispute arising out of or in relation to these Conditions.",
          ],
        },
        {
          title: "Contact",
          policies: [
            <>
              For any questions relating to the use of these Conditions or the
              use of the Services, please contact{" "}
              <Link to={"mailto:changehub@madewithlove.com"} external>
                changehub@madewithlove.com
              </Link>
              .
            </>,
          ],
        },
      ]}
    />
  </Page>
)

export default TermsOfService
