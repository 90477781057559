import PropTypes from "prop-types"
import React from "react"
import styled from "styled-components"
import mediaQueries from "@styles/mediaQueries"
import Box from "./Box"
import Heading1 from "./Headings/Heading1"
import SEO from "./SEO/seo"
import Layout from "./Layout/Layout"
import Container from "./Container"
import MainBackground from "../images/main-bg.png"

const PageHeader = styled(Box)`
  text-align: center;
  position: relative;
  padding: calc(1rem + 4vw) 0;
  
  &:before {
    background: url("${MainBackground}") no-repeat top center;
    content: ""; 
    opacity: 0.5;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    position: absolute;
    
    @media (max-width: ${mediaQueries.lg}) {
      background-size: 80%;
    }     
  }
  
  h1 {
    font-size: 3rem;
  }
`

const Page = ({ title, children }) => (
  <Layout>
    <SEO title={title} />
    <PageHeader>
      <Heading1>{title}</Heading1>
    </PageHeader>
    <Container py={20} as={"article"}>
      {children}
    </Container>
  </Layout>
)

Page.propTypes = {
  title: PropTypes.node.isRequired,
}

export default Page
